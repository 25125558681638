import * as React from "react";
import './styles/Services.css';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import rightarrow from "../../../../assets/icons/right-arrow-blue-icon.svg";
import leftarrow from "../../../../assets/icons/left-arrow-blue-icon.svg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './styles/slickSlider.css';

import MRFAPP from '../../../../assets/our-services-images/mrf-app.png';
import MyFamily from '../../../../assets/our-services-images/my-family.png';
import EmergencyKit from '../../../../assets/our-services-images/emergency-kit.png';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const OurServices = () => {

  const servicesCard = [
    {
      title: 'My Family Plan',
      content: 'Disasters can strike anywhere, anytime, and without notice. Have you ever thought of how to survive if services such as',
      smallcontent: 'Disasters can strike anywhere, anytime, and without notice. Have you ever thought of how to survive if services such as',
      image: MyFamily,
      navLink:"/knowledgeBase/risk-by-location",


    },
    {
      title: 'Family Emergency Kit',
      content: 'Having a go-kit or not having one should never be a topic for conversation. We consider it a MUST….Every household should',
      smallcontent: 'Having a go-kit or not having one should never be a topic for conversation. We consider it a MUST…',
      image: EmergencyKit,
      navLink: "/ourServices/supply-kits",

    },
    {
      title: 'MYREDFOLDER® APP',
      content: 'Activate your Family plan from your smartphone or tablet. Review the protected information instantly on your phone',
      smallcontent: 'Activate your Family plan from your smartphone or tablet. Review the protected information instantly ...',
      image: MRFAPP,
      navLink: "/ourServices/my-mrf-app",

    },
    {
      title:'My Family Plan',
      content:'Disasters can strike anywhere, anytime, and without notice. Have you ever thought of how to survive if services such as',
      smallcontent:'Disasters can strike anywhere, anytime, and without notice. Have you ever thought of how to survive if services such as',
      image: MyFamily,
      navLink:"/knowledgeBase/risk-by-location",
     
    
    },
    {
      title:'Family Emergency Kit',
      content:'Having a go-kit or not having one should never be a topic for conversation. We consider it a MUST….Every household should',
      smallcontent:'Having a go-kit or not having one should never be a topic for conversation. We consider it a MUST…',
      image: EmergencyKit,
      navLink:"/ourServices/supply-kits",
     
    },
    {
      title:'MYREDFOLDER® APP',
      content:'Activate your Family plan from your smartphone or tablet. Review the protected information instantly on your phone',
      smallcontent:'Activate your Family plan from your smartphone or tablet. Review the protected information instantly ...',
      image: MRFAPP,
      navLink:"/ourServices/my-mrf-app",
     
    },
  ]
  const responsive = [
    
  
    {
      breakpoint: { max: 5000, min: 1200 },
      slidesToShow: 3, 
     
    },
    {
      breakpoint: { max: 1200, min: 1000 },
      slidesToShow: 3, 
     
    },
    {
      breakpoint: { max: 999, min: 768 },
      slidesToShow: 3, 
   
    },
    {
      breakpoint: { max: 767, min: 0 },
      slidesToShow: 1, 
     
    },
  ];

  const settings = {
    // dots: true,
    // slidesToScroll={1}
    // speed={500}
    infinite: true,
    centerMode: true,
    lazyLoad: 'anticipated',
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
    ],
  };

  
  
  return (
    <Grid container item className="services">
    <div  className="home-container-services">
       {/* <Grid container className="services"> */}
    {/* <Grid item xs={2} md={1} lg={1}></Grid> */}
      
   
    <Grid item xs={12} md={12} ls={12} >
      <div>
        <h2 style={{textAlign:'center'}} className="primary-font-bold-heading">Our Services</h2>
      </div>
           
       {/* <Carousel  className="custom-carousel"
        responsive={responsive}
        arrows
        infinite
        style={{paddingBottom:'20px'}}
    > */}
    <Slider
    {...settings}
              >
      {servicesCard.map((item, i) =>{
        return(
          <Item key={i} singleCard={item} />
        )
      })}
      </Slider>
       {/* </Carousel> */}
      </Grid>
      {/* <Grid item xs={2} md={1} lg={1}></Grid> */}
    {/* </Grid> */}
      </div>
     </Grid>
  );
};

function Item({ key, singleCard }) {
  const width = useWidth();
  let contentLength = 'content';
  // let cardWidth = '350px'
  // if(width === 'sm' || width === 'md'){
  //   cardWidth = '200px';
  //   contentLength = 'smallcontent';
  // } else if(width === 'lg'){
  //   cardWidth = '250px';
  //   contentLength = 'smallcontent';
  // }
  return( <Grid container item     sx={{ paddingBottom:'10px', "&.MuiGrid-root " :{justifyContent:"center"}}}>
    <Card sx={{':hover': { boxShadow:10 }}} key={key} className="services-card">
      <CardMedia
        component="img"
        height="140px"
        image={singleCard.image}
        alt="green iguana"
        style={{ borderRadius: '10px' }}
      />
      <CardContent>
        <Typography className="primary-font-bold1 card-title" >
          {singleCard.title}
        </Typography>
        <Typography sx={{ color: "black", pt: "20px" }} className=" primary-font-normal-7">
          {singleCard[contentLength]}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <a className="read-more primary-font-normal-7" href={singleCard.navLink} >
        <b>Read More </b>
        </a>
      </CardActions>
    </Card>
  </Grid>
  )
}

export default OurServices;
