const remainingMonths = (data) => {
  // const currentDate = new Date();
  
  // const remainingMonthsData = data.filter(event => {
  //   const eventLastDate = new Date(event.date.match(/\b\w+\b\s\d+,\s\d{4}/)[0]);
  //   return eventLastDate > currentDate;
  // });
  
  // const monthsTextFormat = Object.keys(remainingMonthsData.reduce((acc, event) => {
  //   acc[event.month] = true;
  //   return acc;
  // }, {})).map(month => {
  //   const monthIndex = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(month);
  //   return monthIndex !== -1 ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][monthIndex] : null;
  // }).filter(month => month !== null);
  const months = {
    "January": 0,
    "February": 1,
    "March": 2,
    "April": 3,
    "May": 4,
    "June": 5,
    "July": 6,
    "August": 7,
    "September": 8,
    "October": 9,
    "November": 10,
    "December": 11
  };

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();  // getMonth() returns month index (0 for January, 1 for February, etc.)
  const currentDay = currentDate.getDate();

  const remainingMonthsData = data.filter(event => {
    const eventMonth = months[event.month];
    const eventDay = event.lastDate;

    if (eventMonth > currentMonth) {
      return true;
    }

    if (eventMonth === currentMonth && eventDay >= currentDay) {
      return true;
    }

    return false;
  }); 

return remainingMonthsData;
}


const eventsdata = [

  {
    title: 'International Conference: Big Data for Disaster Response ...',
    month :"February",
    lastDate : 17,
    tooltipTitle:"International Conference...",
    date:`<p>Thu, February 15, 2024, to <br>  Fri, February 17, 2024</p>`,
    location: 'Sandai, Japan',
    content: 'The Asian Development Bank (ADB) and the International Research Institute of Disaster Science (IRIDeS)...',
    navlink:"https://www.apru.org/event/international-conference-big-data-for-disaster-response-and-management-in-asia-and-the-pacific/"
  },
  {
    title: 'Crisis and Business Resilience 2024  ',
    month :"March",
    lastDate : 20,
    tooltipTitle:"Crisis and Business...",
    date:`<p>Mon, March 18, 2024, to <br>  Thu, March 20, 2024</p>`,
    location: 'Sydney ‚ Australia ',
    content: 'The 3rd Annual Crisis & Business Resilience Conference aims to provide practical ...',
    navlink:"https://www.marcusevans.com/conferences/crisisresilience/agenda"
  },
 
  {
    title: 'National Hurricane Conference  ',
    month :"March",
    lastDate : 28,
    tooltipTitle:"National Hurricane...",
   date:`<p> Mon, March 25, 2024, to <br>  Thu, March 28, 2024</p>`,
    location: ' Orlando, FL ',
    content: 'The primary goal of the National Hurricane Conference... ',
    navlink:"https://hurricanemeeting.com/"
  },
  {
    title: '2024 ASPA Annual Conference  ',
    month :"April",
    lastDate : 16,
    tooltipTitle:"2024 ASPA Annual...",
    date:`<p>Fri, April 12, 2024, to <br>  Tue, April 16, 2024</p>`,
    location: 'Minneapolis, Minnesota ',
    content: 'What does building resilient communities mean? We often think of “resilience” as ...',
    navlink:"https://www.aspanet.org/ASPA/ASPA/Events/Annual-Conference/2024-ACTracks-Theme.aspx"
  },
  {
    title: 'ICUDRPUR 2024: 18. International Conference...   ',
    month :"June",
    lastDate : 4,
    tooltipTitle:"ICUDRPUR 2024...",
    date:`<p>Mon, June 03, 2024, to <br>  Tue, June 04, 2024</p>`,
    location: ' New York, United States ',
    content: 'The International Research Conference is a federated organization dedicated to... ',
    navlink:"https://waset.org/urban-disaster-recovery-planning-and-urban-resilience-conference-in-june-2024-in-new-york"
  },
  {
    title: 'Understanding Risk Forum 2024 (UR24) ',
    month :"July",
    lastDate : 21,
    tooltipTitle:"Understanding Risk...",
    date:`<p>Sun, June 16, 2024, to <br>  Fri, June 21, 2024</p>`,
    location: 'Himeji city, Japan',
    content: 'Fostering new knowledge, innovation, and collaboration in disaster risk management...',
    navlink:"https://www.gfdrr.org/en/events"
  },
  {
    title:'International Conference on Disasters Management',
    month:"June",
    lastDate : 22,
    tooltipTitle:"International Conference on Disasters Management...",
    date:`<p> Fri, June 21, 2024, to <br> Sat, June 22, 2024 </p> `,
    location:' Los Angeles, USA',
    content:'The main purpose of International Conference on Disasters Management...',
    navlink:'https://iser.org.in/conf/index.php?id=2325706'
  },
  {
    title:'International Conference on Civil Engineering',
    month:'June',
    lastDate:22,
    tooltipTitle:"International Conference on Civil Engineering, Architecture ...",
    date:`<p> Fri, June 21, 2024, to <br> Sat, June 22, 2024 </p> `,
    location:' Miami, USA',
    content:'The main purpose of International Conference on Civil Engineering, Architecture...',
    navlink:'https://scholarsforum.org/event/index.php?id=2384136'
  },
  {
    title:'International Conference on Natural Disaster',
    month:'June',
    lastDate:25,
    tooltipTitle:" International Conference on Natural Disaster Management...",
    date:`<p>  Mon, June 24, 2024 to <br> Tue, June 25, 2024 </p> `,
    location:'Boston, USA',
    content:'The main purpose of International Conference on Natural Disaster Management...',
    navlink:'https://worldacademics.net/event/index.php?id=2385858'
  },
  {
    title:'International Conference on Geomatics, Disasters',
    month:'June',
    lastDate:28,
    tooltipTitle:"International Conference on Geomatics, Disasters Management and Planning (ICGDMP).",
    date:`<p>  Thu, June 27, 2024 to <br> Fri June 28, 2024</p> `,
    location:'Chicago, USA',
    content:'The main purpose of International Conference on Geomatics, Disasters...',
    navlink:'https://www.researchfora.net/event/index.php?id=2280165'
  },
  {
    title:'International Conference on Disasters Management',
    month:'June',
    lastDate:29,
    tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Planning (ICDMGSP)",
    date:`<p> Fri, June 28, 2024 to <br> Sat, June 29, 2024 </p> `,
    location:'Columbus, USA',
    content:'The main purpose of International Conference on Disasters Management...',
    navlink:'https://www.wrfconference.com/event/index.php?id=2324801'
  },
  {
  title:'International Conference on Civil Engineering',
  month:'July',
  lastDate:3,
  tooltipTitle:"International Conference on Civil Engineering, Architecture and Disaster Management (ICCEADM)",
  date:`<p> Tue, July 02, 2024 to <br> Wed, July 03, 2024 </p> `,
  location:'Boston, USA',
  content:'The main purpose of International Conference on Civil Engineering, Architecture and...',
  navlink:'https://scholarsforum.org/event/index.php?id=2396854'
  },
  {
  title:'International Conference on Disaster Nursing Emergency',
  month:'July',
  lastDate:4,
  tooltipTitle:"International Conference on Disaster Nursing and Emergency Nursing Management (ICDNENM)",
  date:`<p>  Wed, July 03, 2024 to <br> Thu, July 04, 2024 </p> `,
  location:'Austin, USA',
  content:'The main purpose of International Conference on Disaster Nursing and Emergency...',
  navlink:'https://iiter.org/conf/index.php?id=2402394'
  },
  {
    title:'International Conference on Integrated Natural Disaster ',
    month:'July',
    lastDate:5,
    tooltipTitle:"International Conference on Integrated Natural Disaster Management (ICINDM)",
    date:`<p> Thu, July 04, 2024 to <br> Fri, July 05, 2024</p> `,
    location:'Columbus, USA',
    content:'The main purpose of International Conference on Integrated Natural Disaster...',
    navlink:'https://www.iirst.com/event/index.php?id=2390565'
  },
  {
    title: '12th Annual Building Resilience Conference',
    month :"July",
    lastDate : 27,
    tooltipTitle:"12th Annual Building...",
    date:`<p>Fri, July 26, 2024, to <br>  Sat, July 27, 2024</p>`,
    location: 'Washington, D.C',
    content: 'On July 26 and 27, the U.S. Chamber Foundation hosted the 12th annual Building...',
    navlink:"https://events.uschamberfoundation.org/BuildingResilienceConference_2023"
  },
  {
    title:'International Conference on Geomatics, Disasters',
    month:'August',
    lastDate:3,
    tooltipTitle:"International Conference on Geomatics, Disasters Management and Methods (ICGDMM)",
    date:`<p> Fri,Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
    location:'Chicago, USA',
    content:'International Conference on Geomatics, Disasters Management and Methods...',
    navlink:'https://itar.in/conf/index.php?id=2417017'
  },
  {
    title:'International Conference on Structural Engineering',
    month:'August',
    lastDate:3,
    tooltipTitle:"International Conference on Structural Engineering and Disaster Management (ICSEADM)",
    date:`<p> Fri, Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
    location:'New York, USA',
    content:'We are thrilled to invite you to attend the International Conference on Structural...',
    navlink:'https://www.researchfora.net/event/index.php?id=2431121'
  },
  {
    title:'International Conference on Geomatics, Disasters',
    month:'August',
    lastDate:3,
    tooltipTitle:"International Conference on Geomatics, Disasters Management and Methods (ICGDMM)",
    date:`<p> Fri, Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
    location:'Boston, USA',
    content:'International Conference on Geomatics, Disasters Management and Methods...',
    navlink:'https://scienceleagues.com/events/index.php?id=2435249'
  },
  {
    title:'International Conference on Disasters Management,',
    month:'September',
    lastDate:5,
    tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Satellite Data (ICDMGSSD)",
    date:`<p> Wed, Sep 04, 2024 to <br> Thu ,Sep 05, 2024</p> `,
    location:'Boston, USA',
    content:'The systematic dissemination of the latest research findings and knowledge is crucial for the...',
    navlink:'https://scholarsforum.org/event/index.php?id=2463036'
  },
  {
    title:' International Conference on Emergency Management  ',
    month:'September',
    lastDate:6,
    tooltipTitle:"International Conference on Emergency Management and Disaster Management (ICEMDM)",
    date:`<p> Thu, Sep 05, 2024 to <br> Fri, Sep 06, 2024</p> `,
    location:'Boston, USA',
    content:'Welcome to International Conference on Emergency Management and Disaster...',
    navlink:'https://isit.org.in/event/index.php?id=2469445'
  },
  {
    title:'International Conference on Disasters Management',
    month:'September',
    lastDate:7,
    tooltipTitle:"International Conference on Disasters Management Method and Geomatics Solutions (ICDMMGS)",
    date:`<p> Fri,Sep 06, 2024 to <br> Sat ,Sep 07, 2024</p> `,
    location:'Boston, USA',
    content:'ISER is delighted to welcome you to the International Conference on Disasters Management...',
    navlink:'https://iser.org.in/conf/index.php?id=2298010'
  },
  {
    title:'International Conference on Business Continuity',
    month:'October',
    lastDate:5,
    tooltipTitle:"International Conference on Business Continuity Management and Disaster Recovery (ICBCMDR)",
    date:`<p> Fri, Oct 04, 2024 to <br> Sat, Oct 05, 2024 </p> `,
    location:'Las vegas, USA',
    content:'International Conference on Business Continuity Management and Disaster...',
    navlink:'https://itar.in/conf/index.php?id=2529639'
  },
  {
    title:'International Conference on Seismic Engineering ',
    month:'October',
    lastDate:5,
    tooltipTitle:"International Conference on Seismic Engineering and Disaster Management (ICSEDM)",
    date:`<p> Fri, Oct 04, 2024 to <br> Sat, Oct 05, 2024 </p> `,
    location:'Los Angeles, USA',
    content:'Join us at the highly anticipated International Conference on Seismic Engineering and...',
    navlink:'https://sairap.org/conf/index.php?id=2542454'
  },
  {
    title:'International Conference on Disasters Management',
    month:'October',
    lastDate:11,
    tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Satellite Data (ICDMGSSD)",
    date:`<p> Thu, Oct 10, 2024 to <br> Fri, Oct 11, 2024 </p> `,
    location:'Phoenix, USA',
    content:'Join us for the highly anticipated International Conference on Disasters Management,..',
    navlink:'https://researchleagues.com/event/index.php?id=2547428'
  },
  {
    title:'International Conference on Civil Defense',
    month:'November',
    lastDate:5,
    tooltipTitle:"International Conference on Civil Defense and Disaster Management (ICCDDM)",
    date:`<p> Mon, Nov 04, 2024 to <br> Tue, Nov 05, 2024 </p> `,
    location:'Las vegas, USA',
    content:'ISER is delighted to welcome you to the International Conference on Civil Defense...',
    navlink:'https://iser.org.in/conf/index.php?id=2563801'
  },
  {
    title:'International Conference on Forest Fire Disaster',
    month:'November',
    lastDate:5,
    tooltipTitle:"International Conference on Forest Fire Disaster Management (ICFFDM)",
    date:`<p>  Mon, Nov 04, 2024 to <br> Tue, Nov 05, 2024</p> `,
    location:'New York, USA',
    content:'With sincere pleasure and honour, IITER welcomes you to attend International...',
    navlink:'https://iiter.org/conf/index.php?id=2564247'
  },
  {
    title:'International Conference on Geomatics, Disasters ',
    month:'November',
    lastDate:8,
    tooltipTitle:"International Conference on Geomatics, Disasters Management and Remote Sensing (ICGDMRS)",
    date:`<p> Thu, Nov 07, 2024 to <br> Fri, Nov 08, 2024 </p> `,
    location:'New York, USA',
    content:'Science Society delighted to welcome academicians, students , researchers and...',
    navlink:'https://sciencesociety.co/conf/index.php?id=2587943'
  },
  {
    title:'International Conference on Building Designs ',
    month:'December',
    lastDate:3,
    tooltipTitle:"International Conference on Building Designs and Disaster Management (ICBDDM)",
    date:`<p>  Mon, Dec 02, 2024 to <br> Tue, Dec 03, 2024  </p> `,
    location:'Las vegas, USA',
    content:'Welcome to International Conference on Building Designs and Disaster Management...',
    navlink:'https://isit.org.in/event/index.php?id=2619232'
  },
  {
    title:'International Conference on Civil Defense and Disaster',
    month:'December',
    lastDate:6,
    tooltipTitle:"International Conference on Civil Defense and Disaster Management (ICCDDM)",
    date:`<p> Thu, Dec 05, 2024 to <br> Fri, Dec 06, 2024 </p> `,
    location:'Austin, USA',
    content:'ISER is delighted to welcome you to the International Conference on Civil Defense and Disaster...',
    navlink:'https://iser.org.in/conf/index.php?id=2573798'
  },

  {
    title:'International Conference on Disaster Management',
    month:'December',
    lastDate:12,
    tooltipTitle:"International Conference on Disaster Management (ICDM)",
    date:`<p> Wed, Dec 11, 2024 to <br> Thu, Dec 12, 2024 </p> `,
    location:'California, USA',
    content:'Science Society delighted to welcome academicians, students , researchers and...',
    navlink:'https://sciencesociety.co/conf/index.php?id=2623004'
  },
  {
    title:'International Conference on Integrated Natural Disaster',
    month:'December',
    lastDate:13,
    tooltipTitle:"International Conference on Integrated Natural Disaster Management (ICINDM)",
    date:`<p> Thu, Dec 12, 2024 to <br> Fri, Dec 13, 2024 </p> `,
    location:'New York, USA',
    content:'The International Conference on Integrated Natural Disaster Management (ICINDM-24) will be held in New York...',
    navlink:'https://researchplus.co/event/index.php?id=2539331'
  },
  
];
export const data = remainingMonths(eventsdata);
  // {
  //   title: 'Equal Justice Works Hosts Disaster Resilience...',
  //   tooltipTitle:"Equal Justice Works...",
  //   date:`<p>Fri, March 15, 2023, to <br>  Sat, March 16, 2023</p>`,
  //   location: 'Washington, D.C',
  //   content: 'Equal Justice Works, the nation’s largest facilitator of opportunities in public interest law, announced ...',
  //   navlink:"https://www.equaljusticeworks.org/news/equal-justice-works-hosts-disaster-resilience-symposium-with-community-partners/"
  // },
  // {
  //   title: '2022 Nevada Preparedness Summit',
  //   tooltipTitle:"2022 Nevada Preparedness...",
  //   date:`<p>Tue, February 22, 2022, to <br>  Fri, February 25, 2022</p>`,
  //   location: 'Las Vegas, Nevada',
  //   content: 'Registration is now open for the 2022 Nevada Preparedness Summit.',
  //   navlink:"https://preparenv.org/Summit"
  // },
  // {
  //   title: '2022 Colorado Emergency Management Conference',
  //   tooltipTitle:"2022 Colorado Emergency...",
  //   date:`<p>Tue, February 22, 2022, to <br>  Fri, February 25, 2022</p>`,
  //   location: 'Loveland, Colorado',
  //   content: 'The Colorado Emergency Management Association and the Colorado Division of Homeland Security …',
  //   navlink: "https://www.cemacolorado.com/"
  // },
  // {
  //   title: 'QSO Today Virtual Ham Expo',
  //   tooltipTitle:"QSO Today Virtual...",
  //   date:`<p>Sat , March 12, 2022, to <br>  Sun, March 13, 2022</p>`,
  //   location: 'Virtual',
  //   content: 'The Covid-19 Pandemic closed ham radio conventions, hamfests, and monthly meetings all across …',
  //   navlink: "https://www.qsotodayhamexpo.com/"
  // },
  // {
  //   title: '2022 IWCE Annual Conference',
  //   tooltipTitle:"2022 IWCE Annual...",
  //   date:`<p>Mon, March 21, 2022, to <br>  Thu, March 24, 2022</p>`,
  //   location: 'Las Vegas, Nevada',
  //   content: 'Connecting Critical Communications Professionals to Create a Safer, More Efficient and More …',
  //   navlink:"https://iwceexpo.com/"
  // },
  // {
  //   title: 'Virginia Emergency Management Symposium',
  //   tooltipTitle:"Virginia Emergency...",
  //   date:`<p>Mon, March 21, 2022, to <br>  Thu, March 24, 2022</p>`,
  //   location: ' Norfolk, Virginia',
  //   content: 'The Virginia Emergency Management Symposium is an annual event that is co-sponsored by …',
  //   navlink:"https://www.vemaweb.org/index.php?option=com_mc&view=404"
  // },
  // {
  //   title: '2022 IAFC Virtual Wildland Urban Interface Conference',
  //   tooltipTitle:"2022 IAFC Virtual...",
  //   date:`<p>Tue, March 22, 2022, to <br>  Thu, March 24, 2022</p>`,
  //   location: 'Reno, Nevada',
  //   content: 'Save the Date for the International Association of Fire Chiefs Wildland Urban Interface Conference..',
  //   navlink:"https://www.eventscribe.net/2021/WUI/"
  // },
  // {
  //   title: '2022 Joint Civil & DoD CBRN Symposium',
  //   tooltipTitle:"2022 Joint Civil...",
  //   date:`<p>Wed, March 23, 2022, to <br>  Thu, March 24, 2022</p>`,
  //   location: 'National Harbor, MD',
  //   content: 'The 10th Annual Joint Civil & DoD CBRN Symposium will provide a forum for members of the U.S. …',
  //   navlink: "https://jointcbrn.dsigroup.org/"
  // },
  // {
  //   title: '2022 IAEM Mid-Year Virtual Conference',
  //   tooltipTitle:"2022 IAEM Mid-Year...",
  //   date:`<p> Thu, March 24, 2022, to <br>   Fri, March 25, 2022</p>`,
  //   location: 'Virtual',
  //   content: 'IAEM is hosting a mid-year virtual conference, Mar. 24-25, 2022, to bring you two days of brand-new…',
  //   navlink: "https://www.iaem.org/Events/Event-Info/sessionaltcd/220324VIRTUAL-AC"
  // },
  // {
  //   title: 'Border Security Expo 2022',
  //   tooltipTitle:"Border Security...",
  //   date:`<p>Tue, March 29, 2022, to <br>  Wed, March 30, 2022</p>`,
  //   location: 'San Antonio, Texas',
  //   content: 'Held each year in San Antonio, Texas, Border Security Expo offers local, state, and federal law…',
  //   navlink:"https://www.bordersecurityexpo.com/"
  // },
  // {
  //   title: '2022 Preparedness Summit',
  //   tooltipTitle:"2022 Preparedness Summit",
  //   date:`<p>Sun, April 03, 2022, to <br>  Thu, April 07, 2022</p>`,
  //   location: 'Atlanta, Georgia',
  //   content: 'The Preparedness Summit is the first and longest-running national conference on public health…',
  //   navlink: "https://www.preparednesssummit.org/home"
  // },
  // {
  //   title: 'Hardwiring Resiliency Healthcare Conference',
  //   tooltipTitle:"Hardwiring Resiliency...",
  //   date:`<p>Mon, April 04, 2022, to <br>  Tue, April 05, 2022 </p>`,
  //   location: 'Boston, MA',
  //   content: 'The program engages people across departments to ensure their individual vitality and collective strength…',
  //   navlink: "https://www.hardwiringhealthcare.com/"
  // },
  // {
  //   title: 'Critical Infrastructure Protection & Resilience Americas',
  //   tooltipTitle:"Critical Infrastructure...",
  //   date:`<p>Wed, April 06, 2022,  to <br>  Fri, April 08, 2022</p>`,
  //   location: 'New Orleans, Louisiana',
  //   content: 'Critical Infrastructure Protection and Resilience North America brings together leading stakeholders…',
  //   navlink: "https://ciprna-expo.com/"
  // },
  // {
  //   title:'2022 National Hurricane Conference',
  //   tooltipTitle:"2022 National Hurricane...",
  //   date:`<p>Mon, April 11, 2022, to <br>  Thu, April 14, 2022</p>`,
  //   location:' Orlando, Florida',
  //   content:'The primary goal of the National Hurricane Conference is to improve hurricane preparedness, response…',
  //   navlink:" https://hurricanemeeting.com/"
  // },
  // {
  //   title:'ResCon International',
  //   tooltipTitle:"ResCon International",
  //   date:`<p>Tue, April 19, 2022, to <br>  Thu, April 21, 2022 </p>`,
  //   location:' New Orleans, LA',
  //   content:'ResCon is the premier annual international conference on the practice of successful resilience and disaster…',
  //   navlink:" https://resconnola.com/registration/"
  // },
  // {
  //   title:'2022 National VOAD Conference',
  //   tooltipTitle:"2022 National VOAD...",
  //   date:`<p>Mon, May 02, 2022, to <br>  Thu, May 05, 2022 </p>`,
  //   location:'Baltimore, MD',
  //   content:'Save the Date for the National Volunteer Organizations Active in Disaster Conference.',
  //   navlink:"https://www.nvoad.org/nvoadevents/2022-national-voad-conference/"
  // },
  // {
  //   title:"2022 Florida Governor's Hurricane Conference",
  //   tooltipTitle:"2022 Florida Governor's...",
  //   date:`<p>Sun, May 08, 2022, to <br>  Fri, May 13, 2022 </p>`,
  //   location:' Palm Beach, Florida',
  //   content:'The Governor’s Hurricane Conference (GHC) was established in order to provide a vehicle to present lessons…',
  //   navlink:"https://flghc.org/"
  // },
  // {
  //   title:'2022 Great Lakes Homeland Security Training Conference',
  //   tooltipTitle:"2022 Great Lakes...",
  //   date:`<p> Tue, May 10, 2022, to <br>  Thu, May 12, 2022 </p>`,
  //   location:' Grand Rapids, Michigan',
  //   content:'The expo brings together more than1,500 stakeholders for training, demonstrations, and networking relevant…',
  //   navlink:" https://www.michigan.gov/msp/divisions/emhsd/glhs"
  // },
  // {
  //   title:'2022 Texas Emergency Management Conference',
  //   tooltipTitle:"2022 Texas Emergency...",
  //   date:`<p> Tue, May 31, 2022, to <br>  Fri, June 03, 2022 </p>`,
  //   location:' San Antonio, TX',
  //   content:'Planning for the 2022 Texas Emergency Management Conference is underway.',
  //   navlink:"https://www.tdem.texas.gov/conference "
  // },
  // {
  //   title:'2022 National Fire Protection Association Conference and Expo',
  //   tooltipTitle:"2022 National Fire...",
  //   date:`<p>Mon, June 06, 2022, to <br>  Thu, June 09, 2022</p>`,
  //   location:' Boston, Massachusetts',
  //   content:'Planning is underway for NFPA Conference & Expo 2022.',
  //   navlink:" https://www.nfpa.org/conference/"
  // },   
  // {
  //   title:'International Hazardous Materials Response Teams Conference',
  //   tooltipTitle:"International Hazardous...",
  //   date:`<p> Thu, June 09, 2022, to <br> Sun, June 12, 2022</p>`,
  //   location:' Hilton Baltimore, Baltimore, Maryland',
  //   content:" Held each spring, the IAFC's Hazmat Conference provides the latest classroom, hands-on, and field trip…",
  //   navlink:"https://www.iafc.org/events/event/2022/06/09/default-calendar/international-hazardous-materials-response-teams-conference "
  // },
  // {
  //   title:'7th International Symposium on Animal Mortality Management',
  //   tooltipTitle:"7th International Symposium...",
  //   date:`<p>Mon, June 27, 2022, to <br>  Thu, June 30, 2022</p>`,
  //   location:' Raleigh, NC',
  //   content:' The International Symposium on Managing Animal Mortalities is a triennial symposium focusing on collaborating…',
  //   navlink:" https://www.animalmortmgmt.org/"
  // },
  // {
  //   title:'National Homeland Security Conference',
  //   tooltipTitle:"National Homeland...",
  //   date:`<p> Mon, July 11, 2022, to <br>  Thu, July 14, 2022 </p>`,
  //   location:' Cleveland, Ohio',
  //   content:'The 2022 Homeland Security Conference will host a great deal of dynamic content from July 11-14…',
  //   navlink:" https://www.nationalhomelandsecurity.org/"
  // },
  // {
  //   title:'2022 National Association of Counties Annual Conference',
  //   tooltipTitle:"2022 National Association...",
  //   date:`<p>Thu, July 21, 2022,  to <br>  Sun, July 24, 2022</p>`,
  //   location:' Adams County, Colorado',
  //   content:'Save the Date for the 2022 NaCo Annual Conference.',
  //   navlink:" "
  // },
  // {
  //   title:'2022 APCO International',
  //   tooltipTitle:"2022 APCO International",
  //   date:`<p> Sun, August 07, 2022 ,to <br> Wed, August 10, 2022 </p>`,
  //   location:'Anaheim, California',
  //   content:' Planning is underway for APCO 2022! Save the dates and keep checking the website for updates.',
  //   navlink:" https://www.apco2022.org/"
  // },
  // {
  //   title:'2022 Pennsylvania Association of Hazardous Materials Technicians',
  //   tooltipTitle:"2022 Pennsylvania Association...",
  //   date:`<p>Thu, August 25, 2022, to <br>  Sun, August 28, 2022</p>`,
  //   location:' Champion, PA',
  //   content:'Save the date for the 30th Annual Training & Education Conference.',
  //   navlink:" "
  // },
  // {
  //   title:'2022 American Public Health Association Annual Meeting',
  //   tooltipTitle:"2022 American Public...",
  //   date:`<p>Sun, November 06, 2022, to <br>  Wed, November 09, 2022 </p>`,
  //   location:' Boston, Massachusetts',
  //   content:'150 Years of Creating the Healthiest Nation: Leading the Path Toward Equity.',
  //   navlink:" https://www.apha.org/events-and-meetings/annual/past-and-future-annual-meetings"
  // },
  // {
  //   title:'2022 IAEM Annual Conference and EMEX',
  //   tooltipTitle:"2022 IAEM Annual...",
  //   date:`<p>Fri, November 11, 2022, to <br>  Thu, November 17, 2022</p>`,
  //   location:' Savannah, GA',
  //   content:'If you have to pick just one conference to attend this year, the 2022 IAEM 70th Annual Conference…',
  //   navlink:" https://www.iaem.org/usconf"
  // },
  // {
  //   title:'Natural Disaster & Emergency Management (NDEM) Expo 2022',
  //   tooltipTitle:"Natural Disaster...",
  //   date:`<p>Wed, November 16, 2022,  to <br>  Thu, November 17, 2022 </p>`,
  //   location:' New York, NY',
  //   content:'The Natural Disaster & Emergency Management (NDEM) Expo is a comprehensive trade…',
  //   navlink:" "
  // }
// ];
