import React, { useEffect } from "react";
import { Route, Routes,useLocation  } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from "@mui/material/styles";

import IndexComponent from "../../Pages/Home/components/IndexComponent";
import PricingComponent from '../../Pages/Pricing/components/PricingComponent';
import AboutUsComponent from '../../Pages/AboutUs/components/AboutUsComponent';
import OurServicesPageComponent from "../../Pages/OurService/components/OurServicesPageComponent";
import RiskBasedOnLocationDetailIndex from "../../Pages/OurService/components/RiskBasedOnLocationDetailIndex";
import KnowldgeBaseComponents from '../../Pages/KnowldgeBase/components/KnowldgeBaseComponent';
import PrivacyPolicyIndex from "../../Pages/Home/components/PrivacyPolicyIndex";
import SecurityIndex from "../../Pages/Home/components/SecurityIndex";
import RiskBasedOnLocation from "../../Pages/OurService/components/RiskBasedOnMyLocation";
import RiskbasedlocationIndex from "../../Pages/KnowldgeBase/components/RiskbasedlocationIndex";

const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  function ScrollToTop() {
    const location = useLocation();
    
  
    useEffect(() => {
      window.scrollTo(0, 0);
      window.history.scrollRestoration = "manual";
    }, [location]);
  
    return null;
  }
  const Router = (
    <MuiThemeProvider theme={theme}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<IndexComponent />} />
        <Route path="/home" element={<IndexComponent />} />
        <Route path="/pricing" element={<PricingComponent />} />
        <Route path="/walmart" element={<PricingComponent />} />
        <Route path="/aboutUs/OurServices" element={<OurServicesPageComponent/>} />
        <Route path="/aboutUs/our-history" element={<AboutUsComponent page="history" />} />
        <Route path="/aboutUs/our-team" element={<AboutUsComponent page="team" />} />
        <Route path="/aboutUs/our-values" element={<AboutUsComponent page="values" />} />
        <Route path="/aboutUs/our-roles" element={<AboutUsComponent page="roles" />} />
        <Route path="/aboutUs/events" element={<AboutUsComponent page="events" />} />
        <Route path="/aboutUs/contact-us" element={<AboutUsComponent page="contact-us" />} />
        <Route path="/ourServices/supply-kits" element={<OurServicesPageComponent page="supply-kits" />} />
        <Route path="/ourServices/my-mrf-app" element={<OurServicesPageComponent page="my-mrf-app" />} />
        <Route path="/ourServices/my-mrf-plan" element={<OurServicesPageComponent page="my-mrf-plan" />} />
        <Route path="/ourServices/safty" element={<OurServicesPageComponent page="safty" />} />
        <Route path="/ourServices/loss-of-property" element={<OurServicesPageComponent page="loss-of-property" />} />
        <Route path="/ourServices/loss-of-supply" element={<OurServicesPageComponent page="loss-of-supply" />} />
        <Route path="/knowledgeBase/risk-by-location-detail" element={<RiskBasedOnLocationDetailIndex />} />
        <Route path="/knowledgeBase/risk-by-location" element={<RiskbasedlocationIndex/>} />
        <Route path="/knowledgeBase/blog" element={<KnowldgeBaseComponents page="blog" />} />
        <Route path="/knowledgeBase/faq" element={<KnowldgeBaseComponents  page="faq"  />} />
        <Route path="/knowledgeBase/myredfolder-tribe" element={< KnowldgeBaseComponents  page="myredfolder-tribe"  />} />
        <Route path="/security" element={<SecurityIndex  page="security"  />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyIndex page="privacy-policy"/>} />
    </Routes>
  </MuiThemeProvider>
);


export default Router;
  