import * as React from "react";
import './styles/Services.css';
import './styles/NaturalDisaster.css';
import Typography from "@mui/material/Typography";
import { Card, CardMedia, Grid,Box } from "@mui/material";
import {  useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './styles/slickSlider.css'
import {naturalDisasters,manualDiasters} from "../data/disasteraData";
import { useNavigate } from "react-router-dom";
import "./styles/NaturalDisaster.css"
import rightarrow from "../../../../assets/icons/right-arrow-blue-icon.svg";
import leftarrow from "../../../../assets/icons/left-arrow-blue-icon.svg";
import { useEffect } from "react";
import { useState } from "react";


function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const NaturalDisasters = () => {
 
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
 

  const responsive = {
   
    largeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2303, min: 1200 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1199, min: 1000 },
      items: 3
    },
    tablet: {
      breakpoint: { max:999, min: 768},
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  };

  const settings = {
    // dots: true,
    // slidesToScroll={1}
    // speed={500}
    infinite: true,
    centerMode: true,
    // arrows: true,
    lazyLoad: 'anticipated',
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
    ],
  };
  
  return (
    <Grid  container >
    <Grid container item className="home-topcontainer-title" >
    <Grid className="home-container-services">
    {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    <Grid item xs={12} md={12} lg={12}>
      <div>
      <Typography className="naturaldisaster-title primary-font-bold-heading"  >Natural Disasters</Typography>
              <Typography className=" naturaldisaster-contentpara primary-font-normal-14" >What are the typical risks that could occur in your community?</Typography>
          
      </div>
       {/* <Carousel  className="custom-carousel"
        responsive={responsive}
        arrows
        infinite
        style={{paddingBottom:'20px'}}
    >  */}
    <Slider
             {...settings}
              >
      { screenWidth <=890 ? naturalDisasters.map((item, i) =>{
        return(
          <ResponsiveItem key={i} singleCard={item} />
        )
      }) : naturalDisasters.map((item, i) =>{
        return(
          <Item key={i} singleCard={item} />
        )
      })}
      </Slider>
       {/* </Carousel> */}
      </Grid>
      </Grid>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
    <Grid container item className="services">
    {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    <Grid className="home-container-services">
    <Grid item xs={12} md={12} lg={12} className="home-topcontainer-title" >
      <div>
      <Typography className="manmadedisaster-title primary-font-bold-heading" >Man-Made Disasters</Typography>
              <Typography className=" naturaldisaster-contentpara primary-font-normal-14" >These can be divided into different categories and they include technological hazards, sociological hazards and transportation hazards among others.</Typography>
            
      </div>
       {/* <Carousel  className="custom-carousel"
        responsive={responsive}
        arrows
        infinite
        style={{paddingBottom:'20px'}}
    > */}
    <Slider
            {...settings}
              >
      { screenWidth <=890 ? manualDiasters.map((item, i) =>{
        return(
          <ResponsiveItemone key={i} singleCard={item} />
        )
      }) :  manualDiasters.map((item, i) =>{
        return(
          <Itemone key={i} singleCard={item} />
        )
      })
      }
      </Slider>
       {/* </Carousel> */}
      </Grid>
      </Grid>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
    </Grid>
  );
};

function Item({key,singleCard}){
  const navigate = useNavigate();
  const width = useWidth();
  

  // if(width === 'sm' || width === 'md'){
  //   cardWidth = '200px';
  //   contentLength = 'smallcontent';
  // } else if(width === 'lg'){
  //   cardWidth = '250px';
  //   contentLength = 'smallcontent';
  // }
  return( <Grid container item sx={{pb:'10px',"&.MuiGrid-root " :{justifyContent:"center"}}}>
   <Card sx={{width:"260px",':hover': { boxShadow:4 },borderRadius:"10px"}} key={key}  >
   <div onClick={() => { navigate("/knowledgeBase/risk-by-location-detail", { state:singleCard}) }}>
<div>
      <img
        src={singleCard.naturalimage}
        alt="natural disaster"
        // height="200"
        style={{ width:"100%", marginBottom:'5px'}}
       
      />
        <div style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', width: "100%", height: "70px",mt:'20px',mb:'15px'}}>
          {
            singleCard?.last ?  <div style={{ marginBottom:'5px'}}>
              <Typography className='primary-font-bold5' sx={{ fontSize: "16px !important" }} > {singleCard?.first} </Typography>
             <Typography className='primary-font-bold5' sx={{ fontSize: "16px !important" }} > {singleCard?.last} </Typography> </div> :
              <div > <Typography className='primary-font-bold5' sx={{ fontSize: "16px !important",textAlign:"center",alignContent:"center",mt:"-3px" }} > {singleCard?.first} </Typography></div>
          }
        </div>
        </div>     
        </div>     
      </Card>
    </Grid>
   )
}

function ResponsiveItem({key,singleCard}){
  const navigate = useNavigate();
  const width = useWidth();

  return(
     <Grid  item container sx={{pb:'10px',display:"flex",justifyContent:"center",}} >
   <Card sx={{ width:"100%", mx:"8%", ':hover': { boxShadow:4 },borderRadius:"10px"}} key={key}  >
   <div onClick={() => { navigate("/knowledgeBase/risk-by-location-detail", { state:singleCard}) }}>
<div>
      <img
        src={singleCard.naturalimage}
        alt="natural disaster"
        // height="150"
        style={{ width:"100%", marginBottom:'5px'}}
       
      />
        <div style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', width: "100%", height: "70px",mb:'15px'}}>
          {
            singleCard?.last ?  <div style={{ marginBottom:'5px'}}>
              <Typography className='primary-font-bold-disater' sx={{ fontSize: "16px !important" }} > {singleCard?.first} </Typography>
             <Typography className='primary-font-bold-disater' sx={{ fontSize: "16px !important" }} > {singleCard?.last} </Typography> </div> :
              <div > <Typography className='primary-font-bold-disater' sx={{ fontSize: "16px !important",alignContent:"center",mt:"-3px" }} > {singleCard?.first} </Typography></div>
          }
        </div>
        </div>
        </div>          
      </Card>
    </Grid>
   )
}


function ResponsiveItemone({key,singleCard}){
  const navigate = useNavigate();
  const width = useWidth();
  let contentLength = 'content';
  
  return( 
    <Grid  item container sx={{pb:'10px',display:"flex",justifyContent:"center",}} >
    <Card sx={{ width:"100%", mx:"8%", ':hover': { boxShadow:4 },borderRadius:"10px"}} key={key}  >
    <div onClick={() => { navigate("/knowledgeBase/risk-by-location-detail", { state:singleCard}) }}>
 <div>
      <img
        src={singleCard.image}
        alt="manual disaster"
        //  
        style={{width:"100%"}}
      />
     
        <Typography className='primary-font-bold-disater' sx={{ textAlign: 'center', width:"100%",height:"70px",alignContent:"center" }}> {singleCard.name} </Typography>
       
        </div> 
        </div>         
      </Card>
    </Grid>
   )
}


function Itemone({key,singleCard}){
  const navigate = useNavigate();
  const width = useWidth();
  let contentLength = 'content';
  
  return( <Grid container item sx={{pb:'10px',"&.MuiGrid-root " :{justifyContent:"center"} }}>
   <Card sx={{width:"260px",':hover': { boxShadow:4 },borderRadius:"10px"}} key={key} >
   <div onClick={() => { navigate("/knowledgeBase/risk-by-location-detail", { state:singleCard}) }}>
   <div>
      <img
        src={singleCard.image}
        alt="manual disaster"
     
        style={{width:"100%"}}
      />
     
        <Typography className='primary-font-bold5' sx={{ textAlign: 'center', width:"100%",height:"70px",alignContent:"center" }}> {singleCard.name} </Typography>
       
        </div>
        </div>          
      </Card>
    </Grid>
   )
}

export default NaturalDisasters;
